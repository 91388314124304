<template>
  <div class="trade minbox">
    <!-- 头部通用区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
<!--    banner-->
    <section class="solution-banner">
      <div class="main-container">
        <div class="inner">
          <div class="title1">
            机构间场外交易平台
          </div>
          <div class="title2">
            RMB OTC Trading Platfrom
          </div>
        </div>
      </div>
    </section>
<!--    交易平台介绍-->
    <section>
        <div class="section-name">
          交易平台介绍
        </div>
        <div class="section-des w980 txt-center">
          华炫鼎盛根据银行资金业务现在与未来的发展，提供一个闭环生态系，在这个生态系中可以满足银行资金业务几乎所有的场景，这套生态系包含：（1）基于区块链技术的机构间场外交易平台；（2）交易平台上双方确认的交易可以立刻传入资金一体化管理系统进行清算、结算、核算与风险管理；（3）管理系统积累的数据可进行大数据分析，协助交易与风控决策；（4）结合机构间场外交易平台透过机器学习得来的市场与对手画像提供多场景的增值服务。
        </div>
    </section>
<!--    交易模式存在的问题-->
    <section>
      <div class="section-name">
        交易模式存在的问题
      </div>
      <div class="section-des w980 pl150">
        <p>
          机构间场外市场的交易长期发生在交易员的私人社交软件如QQ、微信的好友圈这样的交易模式存在以下问题：
        </p>
        <div class="rules">
          第一条、私人社交软件发布的信息无组织、未标准化，导致交易效率低下。<br>
          第二条、私人社交软件无规范、不受监管，所以风险事件频发。<br>
          第三条、私人社交软件容易使交易员借用机构的市场地位来进行私人利益的勾兑。<br>
          第四条、私人社交软件使交易员离职后，也带著机构的交易对手离开，重建交易圈<br>
          带给机构很大的成本。<br>
          第五条、私人社交软件的私密性也间接让逐年增加的金融犯罪者有恃无恐。
        </div>
        <p>
          “机构间的交易要在基于机构利益的专属交易工具上完成”，打造一个安全且合规的机构间场外交易平台，便成了我们追求的目标。
        </p>
      </div>
    </section>

    <section class="fund">
      <div class="section-name">
        资金业务生态图
      </div>
      <div class="tra-img">
        <img src="../../assets/solutionImg/trans_img.png" alt="">
      </div>
    </section>

    <!-- 底部公共区域 -->
    <section class="boxContentFooter">
      <FooterAll />
    </section>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    data(){
      return{
        activeIndex: '1'
      }
    },
    components: {
      Navbar,
      FooterAll,
    },
  }
</script>
<style lang="scss" scoped>
  @import "./solution.scss";
</style>
<style lang="scss" scoped>
  .w980{
    width: 980px;
    margin: 0 auto;
  }

  .pl150{
    padding-left: 150px;
  }
  .trade{
    .solution-banner{
      background-image: url("../../assets/solutionImg/trading.png");
    }
    .rules{
      margin: 24px 0 ;
    }

    .fund{
      margin-top: 60px;
      overflow: hidden;
      background-position: 50%;
      height: 862px;
      background-size: cover;
      background-image: url("../../assets/solutionImg/fund-bus.png");
      .section-name{
        margin-bottom: 23px;
      }
      .tra-img{
        width: 1130px;
        height: 690px;
        margin: 0 auto;
        img{
          width: 100%;
        }
      }
    }
  }
</style>
